import { useAuth0, User } from "@auth0/auth0-react";
import { ModalButton, PopUpModal } from "components/Modal";
import { useState } from "react";

import { toKebabCase } from "../utils/tracking";

function InputField(
  inputType: string,
  inputName: string,
  label: string,
  halfColumn: boolean,
  ignoreSizing: boolean,
  inputValue: string | undefined
): JSX.Element {

  const classNameDiv =
    ignoreSizing && halfColumn
      ? "col-span-6 col-span-3"
      : halfColumn
        ? "col-span-6 md:col-span-3"
        : "col-span-6";

  const classNameInput =
    "border border-gray-300 rounded-md px-4 py-3 h-8 w-full font-normal tracking-tighter text-black";
  const classNameLabel =
    "font-semibold text-xs pt-6 tracking-tighter text-black opacity-50";
  return (
    <div className={classNameDiv}>
      <label className={classNameLabel}>{label}</label>
      <input
        type={inputType}
        name={toKebabCase(inputName)}
        id={toKebabCase(inputName)}
        className={classNameInput}
        value={inputValue}
        readOnly
      />
    </div>
  );
}

function Label(label: string): JSX.Element {
  return (
    <div className="col-span-6">
      <h3 className="font-semibold pb-4">{label}</h3>
      <hr />
    </div>
  );
}

function Button(
  label: string,
  type: "button" | "reset" | "submit" | undefined,
  isHidden: boolean
): JSX.Element {
  if (!isHidden) {
    return (
      <div className="md:px-1 py-2 text-center md:text-left sm:px-6">
        <button
          type={type}
          className="rounded-full max-w-28 max-h-8 h-8 w-28 bg-white md:bg-white text-xxs outline outline-offset-0 outline-1 outline-platea-grey"
        >
          {label}
        </button>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const Profile = () => {
  const { user } = useAuth0<User>();
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <>
      <div className="flex bg-white justify-center">
        <div className="mt-10 mx-4 max-w-4xl md:m-auto bg-white md:w-1/3">
          <div className="flex items-center">
              <div className="text-left pr-2 pb-4">
                <a
                  className="rounded-full w-8 h-8 text-xxs bg-platea-white flex justify-center align-middle"
                  href="/"
                >
                  <span className="text-xs text-black hover:text-black">&#8592;</span>
                </a>
              </div>
            <div className="flex-auto pb-4">
              <h2 className="text-xl font-semibold ">Mi Cuenta</h2>
            </div>
          </div>
          <div className="max-w-6xl items-center">
            <hr />
          </div>
          <div className="md:flex md:pb-10 pb-5 place-content-center">
            <div className="md:block flex justify-center place-content-center pt-10">
              <div className="md:pr-9  pt-3 relative max-w-18 ">
                <img
                  src={user?.picture}
                  alt="Profile"
                  className="rounded-full max-w-18 text-center"
                />
                {/* TODO: Replace hidden for flex when we want to add the edit picture */}
                {/* <button className="rotate-180 absolute top-3/4 right-0 rounded-full h-8 w-8  items-center justify-center bg-platea-green shadow-black flex" >
                <span className="h-30 w-30  text-white font-semibold text-xl" >
                  &#10000;
                </span>
              </button> */}
              </div>
            </div>
            <div className="flex-auto md:pt-10 pt-4 md:pl-9 ">
              <h2 className="text-2xl md:text-left text-center font-bold italic">
                {user?.name}
              </h2>
              {Button("Platea Member", "submit", false)}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div>
              <form action="#" method="POST">
                <div className="overflow-hidden border border-gray-100 sm:rounded-md">
                  <div className="px-6 py-5 bg-white sm:p-6 ">
                    <div className="grid grid-cols-6 gap-6">
                      {Label("Información Básica")}
                      {InputField(
                        "text",
                        "name",
                        "Nombre",
                        false,
                        true,
                        user?.name
                      )}

                      {InputField(
                        "text",
                        "email-address",
                        "Correo Electrónico",
                        false,
                        false,
                        user?.email
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div>
              <form className="py-10" action="#" method="POST">
                <div className="overflow-hidden border border-gray-100 rounded-2xl">
                  <div className="px-6 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      {Label("Cuenta")}
                      {InputField(
                        "text",
                        "username",
                        "Usuario",
                        false,
                        false,
                        user?.nickname
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* TODO: Set last item to false to show the button */}
            {Button("Plata Membership", "submit", true)}
            <div className="flex justify-center mb-12">
              <ModalButton onClick={setModalOpen}>Remover Cuenta</ModalButton>
            </div>
          </div>
        </div>
      </div>
      <PopUpModal
        title="Remover Cuenta"
        confirmButtonContent="Sí, estoy seguro"
        cancelButtonContent="Cancelar"
        isOpen={isModalOpen}
        setOpen={setModalOpen}
      >
        ¿Estás seguro de remover tu cuenta? Toda tu Información será removida
        permanentemente. Esta acción no es reversible.
      </PopUpModal>
    </>
  );
};

export default Profile;
