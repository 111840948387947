import "index.css";

import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import { Auth0ProviderWithHistory } from "./components/auth";

ReactDOM.render(
  <Router>
    <CookiesProvider>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </CookiesProvider>
  </Router>,
  document.getElementById("auth-app")
);
