import { v4 as uuidv4 } from 'uuid'
import { FocusEvent, MouseEvent } from 'react'

type beam = (a: string, b: string, c: any, d?: boolean, e?: boolean) => void
export type StepType =
  | 'CONTACT'
  | 'PRODUCT'
  | 'CONFIRMATION'
  | 'CREDENTIALS'
  | 'PREFERENCE'
  | 'AUTHENTICATION'
  | 'UNKNOWN'
interface WebContext {
  anonymousId: string
  sessionId: string
  instanceId: string
}

declare global {
  interface Window {
    tagular: beam
    cohesion: (arg: string, cb: (payload?: any) => void) => void
    _Beam: {
      reload: () => void
    }
    _Cohesion: {
      webContext: WebContext
    }
  }
}

export interface ElementClickedInterface {
  location: string
  position: string
  elementType: string
  name: string
  text?: string
  htmlId?: string
  actionOutcome?: string
  outboundUrl?: string
}

export interface FormSubmittedInterface {
  formType: string
  formName: string
  formId: string
  formBrand: string
  captureType?: string
  capturePlacement?: string
  valueProp?: string
  identityRequested?: boolean
  stepType: StepType
}

export interface FieldProperty {
  fieldId?: string
  fieldType: string
  fieldName: string
  fieldLabel: string
  fieldValue?: string
  autofilled?: boolean
}

export interface FormProperty {
  formBrand: string
  formType: string
  formName: string
  formId: string
}
export interface StepContext {
  stepType: StepType
}

export interface IdentifyProperty {
  traits: EmailTraits
  userId: string
  externalIds?: Array<ExternalId>
}

export interface EmailTraits {
  email: string
  name?: string
  phone?: string
}

interface ExternalId {
  id: string
  type: string
  collection: string
  encoding: string
}

interface CaptureContext {
  captureType: string
  capturePlacement: string
  valueProp: string
  identityRequested: boolean
}

interface WebElement {
  location: string
  position: string
  elementType: string
  text: string
  htmlId: string
  name: string
}

interface FormSubmittedProperties {
  '@type': 'redventures.usertracking.v3.FormSubmitted'
  formContext: FormProperty
  captureContext?: CaptureContext
  stepContext: StepContext
}

interface FieldInputtedProperties {
  '@type': 'redventures.usertracking.v3.FieldInputted'
  userInputField: FieldProperty
}

interface ElementClickedProperties {
  '@type': 'redventures.usertracking.v3.ElementClicked'
  webElement: WebElement
  actionOutcome: string
  outboundUrl: string
  correlationId: string | null
}

interface IdentifyProperties {
  '@type': 'core.Identify.v1'
  userId: string
  traits: EmailTraits
  externalIds?: Array<ExternalId>
}

type Properties =
  | ElementClickedProperties
  | FieldInputtedProperties
  | FormSubmittedProperties
  | IdentifyProperties

const track = (type: string, properties: Properties) => {
  window.tagular('beam', type, properties)
}

/**
 * Send the "ElementClicked" event via Tagular.
 *
 * @param e The element's click event.
 * @param data The properties to send with the event.
 */
export const sendElementClicked = (
  e: MouseEvent<any>,
  data: ElementClickedInterface,
): void => {
  const {
    location,
    position,
    elementType,
    text,
    name,
    htmlId,
    actionOutcome,
    outboundUrl,
  } = data

  track('ElementClicked', {
    '@type': 'redventures.usertracking.v3.ElementClicked',
    webElement: {
      location,
      position,
      elementType,
      name,
      text: text ?? e.currentTarget.innerText,
      htmlId: htmlId ?? uuidv4(),
    },
    actionOutcome: actionOutcome ?? '',
    outboundUrl: outboundUrl ?? '',
    correlationId: uuidv4(),
  })
}

/**
 * Send the "FieldInputted" event via Tagular.
 *
 * @param e The input's change event.
 * @param data The properties to send with the event.
 */
export const sendFieldInputted = (
  e: FocusEvent<any>,
  data: FieldProperty,
): void => {
  const {
    fieldId,
    fieldType,
    fieldName,
    fieldLabel,
    fieldValue,
    autofilled,
  } = data

  track('FieldInputted', {
    '@type': 'redventures.usertracking.v3.FieldInputted',
    userInputField: {
      fieldId: fieldId ?? uuidv4(),
      fieldType,
      fieldName,
      fieldLabel,
      fieldValue: fieldValue ?? e.currentTarget.value,
      autofilled,
    },
  })
}

/**
 * Send the "FormSubmitted" event via Tagular.
 *
 * @param data The properties to send with the event.
 */
export const sendFormSubmitted = (data: FormSubmittedInterface): void => {
  const {
    formType,
    formName,
    formId,
    formBrand,
    capturePlacement,
    captureType,
    identityRequested,
    valueProp,
    stepType,
  } = data

  let properties: FormSubmittedProperties = {
    '@type': 'redventures.usertracking.v3.FormSubmitted',
    formContext: {
      formType,
      formName,
      formId,
      formBrand,
    },
    stepContext: {
      stepType: stepType,
    },
  }

  if (capturePlacement || captureType) {
    properties.captureContext = {
      capturePlacement: capturePlacement ?? '',
      captureType: captureType ?? '',
      identityRequested: identityRequested ?? false,
      valueProp: valueProp ?? '',
    }
  }

  track('FormSubmitted', properties)
}

export const sendIdentify = (data: IdentifyProperty) => {
  const { userId, traits, externalIds } = data

  track('Identify', {
    '@type': 'core.Identify.v1',
    userId,
    traits,
    externalIds,
  })
}

/**
 * Tracking with Beam
 */
type EventPayload = ElementClickedInterface | FieldProperty | FormProperty
type EventString = 'elementClick' | 'formSubmit' | 'fieldInput'

/**
 * Adds the necessary HTML attributes to send events via Beam.
 *
 * @param event The name of the event attribute.
 * @param eventPayload The properties to send with the event.
 * @returns An object containing the Beam attributes.
 */
export const addBeamAttributes = (
  event: EventString,
  eventPayload: EventPayload,
) => {
  let attributes: { [key: string]: string } = {}

  attributes['data-tag-event'] = event

  for (let [key, value] of Object.entries(eventPayload)) {
    attributes[`data-tag-${toKebabCase(key)}`] = value
  }

  return attributes
}

/**
 * Converts a given string from camelCase to kebab-case.
 *
 * @param string camelCase string to convert.
 * @returns The kebab-case string.
 */
export const toKebabCase = (text: string) => {
  return text.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}
