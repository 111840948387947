import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'

import { getSameDomainPath, useQueryParam } from '../../utils/url'
import LoadingSpinner from '../LoadingSpinner'

const Login = () => {
  const { loginWithRedirect } = useAuth0()
  const isSignUp = useQueryParam('isSignUp')
  const tab = isSignUp === 'true' ? 'signUp' : 'login'
  const returnTo =
    useQueryParam('returnTo') ?? getSameDomainPath(window.document.referrer)
  const location = useQueryParam('location') ?? 'navigation' // gated | navigation
  const timeoutMs = 1000 * 3

  const cohesionLoaded = (): boolean => {
    return (
      window._Cohesion !== undefined &&
      window._Cohesion.webContext !== undefined &&
      window._Cohesion.webContext.anonymousId !== undefined &&
      window._Cohesion.webContext.sessionId !== undefined &&
      window._Cohesion.webContext.instanceId !== undefined
    )
  }

  const getRedirectOptions = () => {
    const options: RedirectLoginOptions = {
      screen_hint: tab,
      appState: {
        returnTo: returnTo
          ? `/app/callback/?returnTo=${returnTo}&src=${tab}&location=${location}`
          : `/app/callback/?src=${tab}&location=${location}`,
      },
      location: location,
    }
    if (cohesionLoaded()) {
      options['anonymousId'] = window._Cohesion.webContext.anonymousId
      options['sessionId'] = window._Cohesion.webContext.sessionId
      options['instanceId'] = window._Cohesion.webContext.instanceId
    }
    return options as RedirectLoginOptions
  }

  if (cohesionLoaded()) {
    //If cohesion is ready by this point we dont have to wait
    loginWithRedirect(getRedirectOptions())
  }
  setTimeout(() => {
    // If cohesion did not loaded the first attempt, here it will log in with cohesion
    // if loaded after the timeout or else it with log in without cohesion
    loginWithRedirect(getRedirectOptions())
  }, timeoutMs)

  return <LoadingSpinner />
}

export default Login
