import {
  sendIdentify,
  EmailTraits,
} from "./tracking";

/**
 * Sends the Tagular events necessary for newsletter sign up: Identify and FormSubmitted.
 * @param traits Email traits for the Identify event
 * @param formProperties Properties for the FormSubmittted event
 */
const sendEmailEvents = (
  traits: EmailTraits,
) => {
  sha256(traits.email).then((userId) => {
    sendIdentify({ traits, userId });
  });
};

const sha256 = (str: string) => {
  // We transform the string into an arraybuffer.
  const buffer = new TextEncoder().encode(str);
  return crypto.subtle.digest("SHA-256", buffer).then(function (hash) {
    return hex(hash);
  });
};

const hex = (buffer: ArrayBuffer) => {
  const hexCodes = [];
  const view = new DataView(buffer);

  for (let i = 0; i < view.byteLength; i += 4) {
    // Using getUint32 reduces the number of iterations needed (we process 4 bytes each time)
    const value = view.getUint32(i);
    // toString(16) will give the hex representation of the number without padding
    const stringValue = value.toString(16);
    // We use concatenation and slice for padding
    const padding = "00000000";
    const paddedValue = (padding + stringValue).slice(-padding.length);
    hexCodes.push(paddedValue);
  }

  // Join all the hex strings into one
  return hexCodes.join("");
};

export { sendEmailEvents };
