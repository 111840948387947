import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Login, Logout, ProtectedRoute } from 'components/auth';
import LoadingSpinner from 'components/LoadingSpinner';
import Profile from 'views/Profile';
import RedirectLoader from 'components/RedirectLoader';

function App() {
  const { isLoading } = useAuth0();

  if(isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div className="m-auto">
      <Switch>
        <Route path="/app/loading" component={LoadingSpinner}/>
        <Route path="/app/callback" component={RedirectLoader}/>
        <Route path="/app/signin" component={Login} />
        <Route path="/app/signout" component={Logout} />
        <ProtectedRoute path="/app/profile" component={Profile} />
        <Route path="/app/(.+)?/">
          <Redirect to="/app/profile/"/>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
