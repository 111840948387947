import { useAuth0 } from '@auth0/auth0-react';
import  LoadingSpinner  from 'components/LoadingSpinner';
import { useCookies } from 'react-cookie';

const Logout = () => {
  const { logout } = useAuth0();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, removeCookie] = useCookies(["PLATEAUTH", "PLATEAUSER"]);

  removeCookie('PLATEAUTH', {path: '/'});
  removeCookie('PLATEAUSER', { path: '/' });
  
  if (window.initAuthNavigation) {
    window.initAuthNavigation();
  }

  logout({
    returnTo: window.location.origin,
  });

  return <LoadingSpinner/>;
};

export default Logout;