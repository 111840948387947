import { useAuth0 } from "@auth0/auth0-react";
import { SetStateAction } from "react";
import { useCookies } from "react-cookie";

interface ModalButtonProps {
  children: string;
  onClick: (value: React.SetStateAction<boolean>) => void;
}

export function ModalButton(props: ModalButtonProps) {
  const { children, onClick } = props;

  async function handleClick() {
    onClick(true);
  }

  return (
    <button
      type="button"
      className="w-11/12 sm:w-full py-4 rounded-md transition bg-slate-100 text-slate-500 hover:bg-red-600 hover:text-slate-50 active:bg-red-700"
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

interface PopUpModalProps {
  cancelButtonContent: string;
  children: string;
  confirmButtonContent: string;
  isOpen: boolean;
  setOpen: (value: SetStateAction<boolean>) => void;
  title: string;
}

export function PopUpModal(props: PopUpModalProps) {
  const __authApiUrl = process.env.REACT_APP_AUTH_API;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, __, removeCookie] = useCookies(["PLATEAUTH", "PLATEAUSER"]);

  const {
    cancelButtonContent,
    children,
    confirmButtonContent,
    isOpen,
    setOpen,
    title,
  } = props;
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();

  function handleCancel() {
    setOpen(false);
  }

  async function handleConfirm() {
    if (!isAuthenticated) {
      alert("Su sesión ha expirado. Recargue la página y vuelva a intentarlo.");
      return;
    }

    const token = await getAccessTokenSilently({
      detailedResponse: true,
      scope: "openid profile email",
    });

    try {
      const response = await fetch(`${__authApiUrl}/account`, {
        body: JSON.stringify({ id_token: token?.id_token }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "DELETE",
      });

      if (!response.ok) {
        throw Error(
          "No se pudo eliminar su cuenta. Recargue la página y vuelva a intentarlo."
        );
      }

      removeCookie("PLATEAUTH", { path: "/" });
      removeCookie("PLATEAUSER", { path: "/" });

      logout({
        returnTo: "https://www.plateapr.com",
      });
    } catch (err) {
      alert(err);
      setOpen(false);
    }
  }

  return (
    <div
      className={[
        "fixed z-10 inset-0 overflow-y-auto",
        isOpen ? "block" : "hidden",
      ].join(" ")}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-40 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  {title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{children}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleConfirm}
            >
              {confirmButtonContent}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleCancel}
            >
              {cancelButtonContent}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
