import { useAuth0, User } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

import { auth0ExpirationDate } from "../utils/auth0";
import { sendEmailEvents } from "../utils/email";
import { useQueryParam } from "../utils/url";
import LoadingSpinner from "./LoadingSpinner";

const RedirectLoader = () => {
  const returnTo = useQueryParam("returnTo") ?? "/app/profile/";
  const { user } = useAuth0<User>();
  const [cookies, setCookie] = useCookies(["PLATEAUSER"]);
  const source = useQueryParam("src");

  useEffect(() => {
    setCookie("PLATEAUSER", user, {
      path: "/",
      expires: auth0ExpirationDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((source === "login" || source === "signUp") && user && user.email) {
      sendEmailEvents({ email: user?.email, name: user?.name });

      window.cohesion("tagular:eventCompleted", (payload) => {
        if (payload.event === "core.Identify.v1") {
          window.location.replace(`${window.location.origin}${returnTo}`);
        }
      });
    } else {
      window.location.replace(`${window.location.origin}${returnTo}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.PLATEAUSER]);

  return <LoadingSpinner />;
};

export default RedirectLoader;
