import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { auth0MaxAge } from '../../utils/auth0';

declare global {
  interface Window {
    initAuthNavigation: Function;
  }
}

const Auth0ProviderWithHistory = ({ children }: any) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(['PLATEAUTH']);
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    setCookie('PLATEAUTH', true, { path: '/' });

    if (window.initAuthNavigation) {
      window.initAuthNavigation();
    }

    history.push(appState?.returnTo || '/app/callback/');
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/app/callback/`}
      onRedirectCallback={onRedirectCallback}
      cacheLocation='localstorage'
      maxAge={auth0MaxAge}
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
