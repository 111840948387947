import React from "react"
import { useLocation } from "react-router-dom";

/**
 * Gets value of a given URL query parameter.
 * 
 * @param param key of the query parameter.
 * @returns value of the parameter
 */
function useQueryParam(param: string) {
  const { search } = useLocation();
  
  return React.useMemo(() => new URLSearchParams(search).get(param), [search, param]);
}

/**
 * Verifies that a given URL is from the same domain.
 * 
 * @param urlString url to verify
 * @returns url pathname, false if invalid
 */
function getSameDomainPath(urlString: string) {
  if(!urlString) {
    return false;
  }
  
  const url = new URL(urlString);

  if(url.host !== window.location.host) {
    return false;
  }

  return url.pathname;
}

export { useQueryParam, getSameDomainPath };
